import Vue from 'vue';
import Vuex from 'vuex';

import {
  summery
}
from './summery.module';

import {
  bookmarks
}
from './bookmarks.module';

import {
  userrequests
}
from './userrequests.module';


Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    summery,
    bookmarks,
    userrequests
  }
});
