import {
  userrequestService
}
from '../services';

import config from '../Config';

const state = {

};

const actions = {

  async subscribe(state, request) {

      return userrequestService.subscribe(request.email, request.filter,
        request.lang, request.client);

    },
    async confirm(state, searchid) {

      return userrequestService.confirm(searchid);

    },
    async unsubscribe(state, searchid) {

      return userrequestService.unsubscribe(searchid);

    },
    async recordVehicleRequestEmail(state, request) {

      return userrequestService.recordVehicleRequestEmail(request.email,
        request.lang);

    },
    async confirmVehicleRequestEmail(state, requestid) {

      console.log("MODULE", requestid);

      return userrequestService.confirmVehicleRequestEmail(requestid);

    }

};

const mutations = {

};

const getters = {


};



export const userrequests = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
