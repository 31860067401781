import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import checkView from 'vue-check-view'
import VueLazyLoad from 'vue-lazyload'
import VueHtmlToPaper from 'vue-html-to-paper';
import Config from './Config.js'
import VTooltip from 'v-tooltip'
import VueMeta from 'vue-meta'
import Vue2Filters from 'vue2-filters'
  //import * as VueGoogleMaps from 'vue2-google-maps-fixed'
import {
  store
}
from './store';
import Search from './components/Search.vue'
import LocationVehiclesList from './components/LocationVehiclesList.vue'


import {
  i18n
}
from './plugins/i18n.js'


const printoptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://filedelivery.rewaco.com/files/apps/storage-vehicles/semantic.min.css',
    'https://api.rewaco.com/icon.min.css',
    'https://filedelivery.rewaco.com/files/apps/trike-search/css/print.css',
    'https://filedelivery.rewaco.com/files/apps/trike-search/main.css'
  ]
}


Vue.use(VueRouter)
Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  error: 'https://entwicklung-frontend.rewaco.com/fileadmin/apps/assets/image-error.png',
  loading: 'https://entwicklung-frontend.rewaco.com/fileadmin/apps/assets/image-loading2.gif',
  attempt: 1
})
Vue.use(VueHtmlToPaper, printoptions)
Vue.use(checkView)
Vue.use(VTooltip)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(Vue2Filters)
  /*
  Vue.use(VueGoogleMaps, {
    load: {
      key: Config.googleapikey,
      libraries: 'places', // This is required if you use the Autocomplete plugin
    },
    installComponents: true
  })

  */
Vue.filter('uppercase', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})


/*
Vue.filter('currency', function(value) {
  return new Intl.NumberFormat("de-DE", {
    style: 'currency',
    currency: ''
  }).format(value)
})
*/

Vue.filter('power', function(value) {
  return Math.round(value * 1.35962)
})



export const serverBus = new Vue();

const routes = [{
  path: '/',
  component: Search
}, {
  path: '/l',
  component: LocationVehiclesList,
  props: true
}, {
  path: '/l/:opt',
  component: LocationVehiclesList,
  props: true
}, {
  path: '/e',
  component: () =>
    import ('././components/ExtendedSearch.vue')
}, {
  path: '/d/:dealerid',
  component: () =>
    import ('././components/DealerVehicles.vue')
}, {
  path: '/b',
  component: () =>
    import ('././components/BookmarkedVehicles.vue')
}, {
  path: '/v/:id',
  component: () =>
    import ('././components/VehicleDetailView.vue')
}, {
  path: '/v/:id/:contact',
  component: () =>
    import ('././components/VehicleDetailView.vue')
}, {
  path: '/s/:searchid/:state/:type',
  component: () =>
    import ('././components/UserRequestHandler.vue')
}]


const router = new VueRouter({
  routes
})


new Vue({
  el: '#available-vehicles',
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#available-vehicles')
