import config from '../Config';
import 'whatwg-fetch';
import md5 from 'md5';

export const bookmarkService = {
  getBookmarks,
  addBookmark,
  fetchBookmarkedVehicles,
  resetBookmarks
};


async function getBookmarks() {

  let b = localStorage.getItem("bmv");

  return b ? JSON.parse(b) : [];
}

async function addBookmark(vehicleId) {

  let bookmarks = [];
  var smbs = localStorage.getItem("bmv");

  if (!smbs) {
    bookmarks.push(vehicleId);
    localStorage.setItem("bmv", JSON.stringify(bookmarks));
  } else {

    bookmarks = JSON.parse(smbs);
    if (!bookmarks.includes(vehicleId)) {
      bookmarks.push(vehicleId);
    }

    localStorage.setItem("bmv", JSON.stringify(bookmarks));
  }

  return true
}

async function resetBookmarks() {

  localStorage.setItem("bmv", JSON.stringify([]));
}

async function removeBookmark(vehicleId) {

  let bookmarks = [];
  var smbs = localStorage.getItem("bmv");

  if (!smbs) {
    bookmarks.push(vehicleId);
    localStorage.setItem("bmv", JSON.stringify(bookmarks));
  } else {

    bookmarks = JSON.parse(smbs);
    if (!bookmarks.includes(vehicleId)) {
      bookmarks.push(vehicleId);
    }

    localStorage.setItem("bmv", JSON.stringify(bookmarks));
  }
}


async function fetchBookmarkedVehicles(vehiclelist) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword)
    },
    body: JSON.stringify({
      ids: vehiclelist
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/list`,
    requestOptions).then(
    handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
