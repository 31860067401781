import config from '../Config';
import 'whatwg-fetch';
import {
  authHeader
}
from '../utils/auth-header';
import md5 from 'md5';

export const vehiclesService = {
  getAvailableVehiclesCount,
  getAvailableVehicles,
  getAvailableVehiclesByCountryCount,
  getAvailableVehiclesByCountry,
  getAvailableVehiclesByDealer,
  fetchVehicle
};


function getTrackedTours() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/tourtracker/0/20`,
    requestOptions).then(
    handleResponse);
}

function getAvailableVehiclesCount(lat, lng, distance = 500, filter = {}) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/distance/count/${lat}/${lng}/${distance}`,
    requestOptions).then(
    handleResponse);
}

function getAvailableVehicles(lat, lng, distance = 500, filter = {}) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + lat + lng)
    },
    body: JSON.stringify({
      filter
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/distance/${lat}/${lng}/${distance}`,
    requestOptions).then(
    handleResponse);
}

function getAvailableVehiclesByCountryCount(country, filter = {}) {


  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/count/country/${country}`,
    requestOptions).then(
    handleResponse);
}

function getAvailableVehiclesByCountry(country, filter = {}, lang = 'de') {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + country)
    },
    body: JSON.stringify({
      filter,
      lang: lang
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/country/${country}`,
    requestOptions).then(
    handleResponse);
}

function getAvailableVehiclesByDealer(dealerid, lang = 'de') {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lang: lang
    })
  };

  return fetch(
    `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/dealer/${dealerid}`,
    requestOptions).then(
    handleResponse);
}


function fetchVehicle(uid) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': md5(config.masterpassword + uid)
    }
  };

  return fetch(`${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/${uid}`,
    requestOptions).then(
    handleResponse);
}


function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
