<template>
<div>
<div class="wrapperQuickSearch">
  <div class="container containerQuickSearch">
    <div class="col-sm-12 col-md-8 col-lg-8" style="margin-top: 56px;">
      <h1 class="textshadowl" style="color: white; text-transform: uppercase; line-height: 1.5em;">{{$t('Hier findest Du Dein Trike zum besten Preis mit Händler Garantie!')}}</h1>
      <div class="ui bulleted list textshadow" style="color:#ffffff; font-size: 1.25em; text-transform: uppercase; font-weight: 600;">
        <div class="item">{{$t('direkt vom rewaco Partner')}}</div>
        <div class="item">{{$t('Neu & Gebraucht')}}</div>
        <div class="item">{{$t('Wenig Kilometer')}}</div>
        <div class="item">{{$t('sofort verfügbar')}}</div>
        <div class="item">{{$t('vom Fachmann geprüft')}}</div>
      </div>
    </div>
    <div class="col-sm-16 col-md-12 col-lg-9 boxQuickSearch">
      <div class="clearfix"></div>
      <h2 style="color: #000;">{{$t('Trike-Suche')}}</h2>
      <div name="mapsSearch" class="ui form">

        <div class="row step">
          <div class="form-group focused">

            <label for="country" class="col-sm-4 control-label">
                                  {{$t('Land')}}
                              </label>
            <div class="col-sm-20">

              <select class="ui fluid dropdown" v-model="mcountry" @change="setCountry()"><option value=""></option>
                <option v-for="country in countrys" v-bind:value="country.value">{{$t(country.name)}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row step">
          <div class="form-group">
            <label for="placeSearch" class="col-sm-4 control-label">
                                    {{$t('Ort')}}
                              </label>
            <div class="col-sm-20">
              <div class="ui icon input" style="width: 100%;">
                <google-autocomplete id="x-auto" v-bind:prevalue="prevalue" classname="['ui','input']" v-bind:country="mcountry" types="(regions)" v-bind:placeholder="$t('Bitte geben Sie einen Standort ein.')" v-on:placechanged="setAddressData">
                </google-autocomplete>
                <i class="map marker icon"></i>
              </div>

            </div>
          </div>
        </div>
        <div class="row step">
          <div class="form-group">
            <label for="mradius" class="col-sm-4 control-label">
                                  {{$t('Umkreis')}}
                              </label>
            <div class="col-sm-20">
              <select v-bind:class="['ui','fluid','dropdown',{'disabled': !address}]" :disabled="!address" v-model="mdistance"  @change="setDistance()" id="mradius" >
                <option v-for="distance in distanceOptions" v-bind:value="distance">{{distance}} KM</option>
              </select>
            </div>
          </div>
        </div>
        <p></p>
        <div class="row" style="margin-top:20px;">
          <div class="form-group">
            <div class="col-xs-24 col-sm-12" style="margin-bottom:6px;">
              <button @click="openExtendedSearch()" class="ui basic button red"  style="width: 100%;">
                                      {{$t('Erweiterte Suche')}}
                                  </button>
            </div>
            <div class="col-xs-24 col-sm-12  text-right" style="margin-bottom: 6px;">
                <div v-bind:class="['ui', 'red', 'button', {'disabled':availableVehiclesCount <= 0}]" style="width: 100%;" @click="showList()">

                  <i v-if="searching" class="ui active tiny invert dimmer inline loader" style="margin-right: 12px;"></i><i v-if="!searching" v-bind:class="['ui','trike','icon']" style="height: 1em; margin-top: -4px;"></i> {{$t('Suchen')}}
                  <i v-if="availableVehiclesCount >= 0">
                      &nbsp;({{availableVehiclesCount}})
                  </i>
                </div>
            </div>
          </div>
        </div>
          <div v-if="bookmarks.length>0" class="row" style="margin-top:10px;">
              <div class="col-sm-24">
                <div class="ui divider"></div>
                <a @click="openBookmarkList()"><i class="star icon divider"></i>&nbsp;<b>{{$t('Meine Merkliste')}}</b></a>
              </div>
            </div>
      </div>
    </div>
  </div>
</div>

  <div class="container" v-if="showOffers">
    <br>
    <br>
    <div class="col-sm-24">
    <vehicle-offers-carousel></vehicle-offers-carousel>
  </div>
  </div>
</div>
</template>

<script>
import Config from '../Config';
import {
  mapState,
  mapActions
} from 'vuex'
import {
  formatDateOnly
} from '../utils/DateFormatter.js'
import GoogleAutocomplete from './sections/GoogleAutoComplete'

export default {
  name: 'Search',
  props: {},
  data: function() {
    return {
      searching: false,
      mdistance: 0,
      mcountry: "DE",
      prevalue: ''
    }
  },
  components: {
    GoogleAutocomplete,
    'VehicleOffersCarousel': () => import('./sections/VehicleOffersCarousel')
  },
  computed: {
    availableVehiclesCount() {
      return this.$store.state.summery.vehiclesCount;
    },
    distanceOptions() {
      return this.$store.state.summery.distanceOptions;
    },
    countrys() {
      return this.$store.state.summery.countrys;
    },
    distance() {
      return this.$store.state.summery.distance;
    },
    address() {
      return this.$store.state.summery.address
    },
    country() {
      return this.$store.state.summery.country
    },
    filter() {
      return this.$store.state.summery.filter
    },
    showOffers() {
      return this.$store.state.summery.showOffers
    },
    bookmarks(){
       return this.$store.state.bookmarks.bookmarked
    }
  },

  created() {

    this.$store.commit('summery/setFilter', {});
    this.$store.dispatch('bookmarks/getBookmarks');

    if(this.address){
      this.prevalue = this.address.postal_code + ", " + this.address.locality;
      this.mdistance = this.distance;
      this.getAvaileVehicles(this.address.latitude, this.address.longitude, this.distance, this.filter)
    }else{
      this.mcountry= this.country;
      this.setCountry();
    }


  },
  methods: {
    setAddressData: function(addressData, placeResultData, id) {
      this.mdistance = this.distance;
      this.$store.commit('summery/address', addressData);
      this.getAvaileVehicles(this.address.lat,this.address.lng, this.distance, this.filter);
    },
    setDistance: function(){
      this.$store.commit('summery/distance', this.mdistance);
      if(this.address){
        this.getAvaileVehicles(this.address.lat,this.address.lng, this.distance, this.filter);
      }
    },
    setCountry: function() {
      this.$store.commit('summery/address', null);
      this.$store.commit('summery/country', this.mcountry);

      this.getAvaileVehiclesByCountry(this.mcountry,[]);
    },
    getAvaileVehicles: function(lat,lng,distance,filter){
      this.searching = true;
      this.$store.dispatch('summery/availableVehiclesCount', {
        lat: this.address.latitude,
        lng: this.address.longitude,
        distance: distance,
        filter: this.filter
      }).then((count) => {
        this.searching = false;
      })
    },
    getAvaileVehiclesByCountry: function(country,filter){
      this.searching = true;
      this.$store.dispatch('summery/availableVehiclesByCountryCount', {
        country,
        filter: this.filter
      }).then((count) => {
        this.searching = false;
      })
    },
    showList: function(){
      this.$store.commit('summery/clearVehicles');
      this.$store.commit('summery/fetchList', true);
      this.$router.push({
          path: '/l'
        }
      );
    },
    openExtendedSearch: function(){
      this.$router.push({
          path: '/e'
        }
      );
    },
    openBookmarkList: function(){
      this.$router.push({
          path: '/b'
        }
      );
    },
    clearAdressInput: function(){
      //this.prevalue = '';
    }
  }

}
</script>

<style scoped>
.step{
  margin-bottom: 14px;
}

.textshadow {
  text-shadow: 2px 2px rgba(0,0,0,0.3);
}
.textshadowl {
  text-shadow: 2px 2px rgba(0,0,0,0.1);
}

</style>
