<template>
<div>
  <message-overlay :delay="8" :format="'info'"></message-overlay>
  <div class="search-result-header">
    <div class="col-sm-8 center-small " style="padding-top:10px;">
      <span v-if="vehicles.length>0"><h1>{{vehicles.length}} {{$t('Trikes gefunden')}}</h1></span>
    </div>
    <div class="col-sm-8 text-center" style="padding-top:5px;">
      <div style="width: 100%; text-align: center;">
      <select class="form-control ui drop" v-model="sortBy" @change="sortList()" id="sorting" style="max-width: 200px;margin-left: auto;margin-right: auto;">
        <option value="">{{$t('Sortierung')}}</option>
        <option value="preis asc">{{$t('Preis aufsteigend')}}</option>
        <option value="preis desc">{{$t('Preis absteigend')}}</option>
        <option value="kilometer asc">{{$t('Kilometer aufsteigend')}}</option>
        <option value="kilometer desc">Kilometer absteigend</option>
        <option value="erstzulassung_jahr desc">Erstzulassung</option>
      </select>
    </div>
    </div>
    <div class="col-sm-8 center-small text-right" style="padding-top:14px;">
        <a v-if="bookmarks.length > 0" @click="openBookmarkList()">
          <b>{{$t('Meine Merkliste')}}</b>&nbsp;<i class="star icon divider"></i>&nbsp;
        </a>
        <router-link to="/e">
        <a class="ui basic icon" style="min-width: 0px;">
          <b>{{$t('Suche verfeinern')}}</b>&nbsp;<i class="right arrow icon divider"></i>
        </a>
      </router-link>
    </div>
  </div>

  <div v-if="!showEmailInput" class="container" style="margin-bottom:6px; margin-top: 14px;">
      <search-filter :filters="filter" :address="address" :country="country" :lang="lang" @removeFilter="removeFilter($event)" style="display:inline;"></search-filter>
      <div v-if="!showEmailInput && (Object.keys(filter).length > 0 || address)" style="display:inline;">
        &nbsp;
        <a @click="openEmailInput()">
        <b style="white-space: nowrap;">{{$t('Neue Angebote per E-Mail')}}</b>&nbsp;<i class="bell icon divider"></i>&nbsp;
        </a>
      </div>
  </div>

  <div v-if="showEmailInput" class="container" style="margin-top: 14px;">
    <new-offers-by-email :filters="filter" :address="address" :country="country" :lang="lang" @openPrivacy="openPrivacyLink()" @close="closeEmailInput()"></new-offers-by-email>
  </div>

  <div v-if="ready" id="vehiclelist" class="container large-trikelist" style="position:relative;">
      <vehicle-preview v-for="(vehicle, index) in vehicles" v-bind:vehicle="vehicle" v-bind:showDealerLink="true" v-bind:showBookmark="true" v-bind:index='index'
      v-on:dealerselected="openDealerVehiclesPage(vehicle.haendler)"
      v-on:selected="openDetailPage(vehicle.uid, $event)"
      v-on:bookmarkvehicle="bookmarkVehicle(vehicle.uid, $event)"
      v-on:openBookmarkList="openBookmarkList()"
      :key="vehicle.uid">
      </vehicle-preview>
  </div>

  <div v-if="vehicles.length > 10" class="content-top-btn">
    <i class="angle big up icon inverted" style="font-size:3em;" @click="toTop()"></i>
  </div>


  <div v-if="!ready">
    <div class="ui">
        <div class="ui active inverted dimmer">
          <div class="ui active inline centered loader"></div>
        </div>
        <p></p>
        <p></p>
        <p></p>
    </div>
  </div>

</div>
</template>

<script>

import Config from '../Config';
import VehiclePreview from './sections/VehicleListView';
import MessageOverlay from './sections/ShortMessageOverlay';
import SearchFilter from './sections/SearchFilter';
import {
  mapState,
  mapActions
} from 'vuex'
import {
  formatDateOnly
} from '../utils/DateFormatter.js'
import {
  i18n
}
from '../plugins/i18n.js'

export default {
  name: 'List',
  props: {},
  data: function() {
    return {
      ready: false,
      sortBy: '',
      scrollPosition: 0,
      vehicleListChnunk: [],
      chunkPosition: 0,
      showEmailInput: false,
      lang: 'en'
    }
  },
  components: {
    VehiclePreview,
    MessageOverlay,
    'search-filter': SearchFilter,
    'NewOffersByEmail': () => import('./sections/NewOffersByEmail')
  },
  computed: {
    availableVehiclesCount() {
      return this.$store.state.summery.vehiclesCount;
    },
    distanceOptions() {
      return this.$store.state.summery.distanceOptions;
    },
    countrys() {
      return this.$store.state.summery.countrys;
    },
    distance() {
      return this.$store.state.summery.distance;
    },
    address() {
      return this.$store.state.summery.address
    },
    country() {
      return this.$store.state.summery.country
    },
    vehicles() {
      return this.$store.state.summery.vehicles
    },
    filter() {
      return this.$store.state.summery.filter
    },
    fetchList() {
      return this.$store.state.summery.fetchList
    },
    listScrollPosition() {
      return this.$store.state.summery.locationVehicleListScrollPosition
    },
    bookmarks(){
       return this.$store.state.bookmarks.bookmarked
    },
    lastUrlQuery(){
       return this.$store.state.summery.lastUrlQuery
    }
  },
  metaInfo() {
    return{
    link: [
      {rel: 'canonical', href: document.referrer + "#/l"}
    ]
    }
  },
  created() {

    $( "body" ).removeClass( "vue-lb-open" );
    $( "html" ).removeClass( "no-scroll" );

    this.lang = i18n.locale;

    this.handleUrlQuery();

    this.handleFetchList();

    window.addEventListener("scroll", this.setScrollPosition);


  },mounted: function () {

  },
  methods: {

    handleUrlQuery: function(){

      let emptyquery = Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object;

      if(!emptyquery){

        let lastquery = JSON.stringify(this.$route.query);

        let regex = /(\d|true|false)$/;

        if(lastquery != this.lastUrlQuery){
          let opt = {}
          for(let key in this.$route.query){
            if(key == "country"){
              this.$store.commit('summery/country', this.$route.query[key]);
            }else{
              if(regex.test(this.$route.query[key])){
                let k = key.replace(/[^\w\s]/gi, '');
                opt[k] =  {'operator':'=', 'value': this.$route.query[key]};
              }
            }
          }

          this.$store.commit('summery/setLastUrlQuery', lastquery);
          this.$store.commit('summery/setFilter', opt);
          this.$store.commit('summery/clearVehicles');
          this.$store.commit('summery/fetchList', true);

          //this.handleFetchList();
        }

      }
    },
    handleFetchList: function() {
      if(this.fetchList){
            this.$store.dispatch('bookmarks/getBookmarks');
          if(this.address){
            this.$store.dispatch('summery/availableVehicles', {
              lat: this.address.latitude,
              lng: this.address.longitude,
              distance: this.distance,
              filter: this.filter
            }).then((count) => {
              this.$store.commit('summery/fetchList', false);
              this.searching = false;
              this.ready = true;
            })
          }else{
            if(this.country){
              this.$store.dispatch('summery/availableVehiclesByCountry', {
                country: this.country,
                filter: this.filter
              }).then(async () => {
                this.$store.commit('summery/fetchList', false);
                this.searching = false;
                this.ready = true;
                if(this.filter['cf_model'] != undefined){
                  let models = {'m1':'4','m2':'1','m3':'2'};

                  let color = await this.$store.dispatch("summery/getColor",this.filter['cf_maincolor'].value );
                  let model = models[this.filter['cf_model'].value];

                  this.filter['modell'] =  {'operator':'=', 'value': model};
                  this.filter['farbe_grund'] =  {'operator':'=', 'value': color[0]};

                  delete this.filter['cf_framecolor'];
                  delete this.filter['cf_secondcolor'];
                  delete this.filter['cf_maincolor'];
                  delete this.filter['cf_model'];

                  this.$store.commit('summery/setFilter', this.filter);
                }
              })
            }
          }
          this.toTop();
        }else{
          this.ready = true;
          if(this.vehicles.length == 0){
            this.$router.push({
                path: '/'
              }
            );
          }

          setTimeout(() =>{
            window.scrollTo(0,this.listScrollPosition);

          }, 0)

        }
    },
    setScrollPosition: function(position){
      this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    },
    sortList: function(){
      this.$store.commit('summery/sortListBy',this.sortBy);
      setTimeout(() => {
        window.scrollTo(0,1);
        window.scrollTo(0,);
      }, 500);

    },
    openDetailPage: function(uid, contact){
      let path = '/v/' + ( uid + 10000 );
      path += contact ? '/contact' : '';
      this.$router.push({
          path: path
         }
      );
    },
    openDealerVehiclesPage: function(haendler){
      this.$router.push({
          path: '/d/' + haendler
        }
      );
    },
    openBookmarkList: function(){
      this.$router.push({
          path: '/b'
        }
      );
    },
    removeFilter: function(filter){
      this.$store.commit('summery/removeFilter', filter);
    },
    openEmailInput: function(){
      this.showEmailInput = !this.showEmailInput;
    },
    bookmarkVehicle: function(vehicleid, bookmark){
      this.$store.dispatch('bookmarks/addBookmark', vehicleid).then((s) => {
        if(s){
            this.$store.commit('summery/setVehicleBookmarkedState', vehicleid);
        }else{
          this.$emit('showmessage', "Es können max. " + Config.maxBookmarks + " Fahrzeuge gemerkt werden.");
        }
      })
    },
    toTop: function(){
      window.scrollTo(0,1);
      window.scrollTo(0,0);
    },
    closeEmailInput: function(){
      this.showEmailInput = false;
    },
    openPrivacyLink: function(){
      window.open(Config.privacy[this.lang], '_blank');
    }
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.setScrollPosition);
    this.$store.commit('summery/locationVehicleListScrollPosition', this.scrollPosition);
  },
  beforeRouteUpdate (to, from, next) {
    this.handleUrlQuery();
    next();
  },
  watch: {
    '$route.query'() {
      this.handleUrlQuery();
    }
  }


}
</script>

<style scoped>

@media only screen and (max-width: 772px) {
  .center-small{
    text-align: center;
  }

}



</style>
