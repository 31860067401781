<template>
  <div>
    <div class="ui service-list" >
      <div v-if="lieferservice" class="item">
        <i class="check icon green"></i>
        <span class="content service-label">{{$t("Lieferservice")}}</span>
      </div>
      <div v-if="servicepaket" class="item">
        <i class="check icon green"></i>
        <span class="content service-label">{{ $t("Service-Paket") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleServices',
  props: ['lieferservice','servicepaket'],
  data: function() {
    return {

    }
  },
  created: function() {
  }
}

</script>

<style scoped>

.item{
  margin-bottom: 2px;
}
.service-label {
    color: black;
    font-family: Anton,sans-serif;
    letter-spacing: 1px;
}
.service-list{
  width: 100%!important;
  float: right;
}

@media only screen and (max-width: 772px) {

  .service-list .item{
    float: left;
    margin-right: 8px;
  }


}
</style>
