import {
  vehiclesService
}
from '../services';

import {
  bookmarkService
}
from '../services';
import md5 from 'md5';

import PrepareFilter from '../utils/PrepareFilter';
import config from '../Config';

const state = {
  status: {},
  vehicles: [],
  dealervehicles: [],
  dealerId: null,
  fetchList: false,
  fetchDealerList: true,
  address: null,
  distance: 100,
  country: 'DE',
  searchstring: null,
  showOffers: true,
  vehiclesCount: -1,
  filter: new Map(),
  bookmarks: [],
  bookmarkedVehicles: [],
  vehicleOffers: [],
  lastUrlQuery: "",
  vehicle: null,
  vat: 19,
  distanceOptions: [10, 20, 50, 100, 200, 500],
  locationVehicleListScrollPosition: 0,
  dealerVehicleListScrollPosition: 0,
  countrys: [{
    name: "Deutschland",
    value: "DE"
  }, {
    name: "Frankreich",
    value: "FR"
  }, {
    name: "Italien",
    value: "IT"
  }, {
    name: "Kanada",
    value: "CA"
  }, {
    name: "Luxemburg",
    value: "LU"
  }, {
    name: "Niederlande",
    value: "NL"
  }, {
    name: "Schweiz",
    value: "CH"
  }, {
    name: "Vereinigte Staaten",
    value: "US"
  }, {
    name: "Vereinigtes Königreich",
    value: "UK"
  }, {
    name: "Österreich",
    value: "AT"
  }]
};

const actions = {

  async availableVehiclesCount(state, opt) {

      await vehiclesService.getAvailableVehiclesCount(opt.lat, opt.lng, opt.distance,
          opt.filter)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('summery/availableVehiclesCountSuccess', data.count);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true

    },
    async availableVehiclesByCountryCount(state, opt) {

      await vehiclesService.getAvailableVehiclesByCountryCount(opt.country,
          opt.filter)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('summery/availableVehiclesCountSuccess', data.count);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true
    },
    async availableVehiclesByCountry(state, opt) {

      await vehiclesService.getAvailableVehiclesByCountry(opt.country,
          opt.filter)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('summery/availableVehiclesSuccess', data);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true
    },
    async availableVehiclesByDealer(state, opt) {

      await vehiclesService.getAvailableVehiclesByDealer(opt.dealerid)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('summery/availableDealerVehiclesSuccess', data);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true
    },
    async availableVehicles(state, opt) {

      await vehiclesService.getAvailableVehicles(opt.lat, opt.lng, opt.distance,
          opt.filter)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('summery/availableVehiclesSuccess', data);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true
    },
    async fetchVehicle(state, uid) {

      await vehiclesService.fetchVehicle(uid)
        .then(
          async data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {

              let d = data[0];

              if (d) {
                let images = await mapImages(d.images);

                d.images = images;
              }

              this.commit('summery/fetchedVehicleSuccess', d);
            }
          },
          error => {
            this.dispatch('summery/error', error, {
              root: true
            });
          }
        );

      return true
    },
    async error(state, error) {
      console.log("Error");

    },
    async getColor(state, code) {

        let color = {};

        const requestOptions = {
          method: 'GET',
          headers: {
            "x-access-token": md5(config.masterpassword),
            'Content-Type': 'application/json;charset=UTF-8'
          }
        };

        await fetch(
          `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_APIHOST}/vehicles/color/${code}`,
          requestOptions).then(res => {
          return res.text().then(text => {
            let vh = JSON.parse(text);

            color = vh;

          })
        });


        return color;

      }
};

function mapImages(i) {
  return Promise.all(i.map((image) => {
    return {
      src: config.filedelivery + image,
      org: image
    };
  }));
}
const mutations = {
  availableVehiclesCountSuccess(state, count) {
      state.vehiclesCount = count;
    },
    availableVehiclesCountFailure(state) {
      state.vehiclesCount = -1;
    },
    async availableVehiclesSuccess(state, vehicles) {

      await bookmarkService.getBookmarks().then(b => {
        state.bookmarks = b;
      }, e => {
        state.vehicles = vehicles.sort(function() {
          return 0.5 - Math.random()
        });
      })

      vehicles = vehicles.map((v) => {
        let d = v;
        d.bookmarked = state.bookmarks.indexOf(d.uid) >= 0;
        d.currency = d.currency == '' ? '€' : d.currency;
        d.verfuegbar_ab = (d.verfuegbar_ab == '0000-00-00 00:00:00' || d.verfuegbar_ab == '') ? '' : d.verfuegbar_ab;
        return d;
      });

      state.vehicles = vehicles.sort(function() {
        return 0.5 - Math.random()
      });
    },
    availableDealerVehiclesSuccess(state, vehicles) {
      vehicles = vehicles.map((v) => {
        let d = v;
        d.currency = d.currency == '' ? '€' : d.currency;
        d.verfuegbar_ab = (d.verfuegbar_ab == '0000-00-00 00:00:00' || d.verfuegbar_ab == '') ? '' : d.verfuegbar_ab;
        return d;
      });
      state.dealervehicles = vehicles
    },
    setVehicleBookmarkedState(state, vehicleid) {
      for (let index in state.vehicles) {
        if (state.vehicles[index].uid == vehicleid) {
          state.vehicles[index].bookmarked = true;
          break;
        }
      }
    },
    resetAllBookmarkedVehicles(state) {
      for (let index in state.vehicles) {
        state.vehicles[index].bookmarked = false;
      }
    },
    fetchedVehicleSuccess(state, vehicle) {
      vehicle.verfuegbar_ab = (vehicle.verfuegbar_ab == '0000-00-00 00:00:00' || vehicle.verfuegbar_ab == '') ? '' : vehicle.verfuegbar_ab;
      state.vehicle = vehicle;
    },
    setVehicleOffers(state, vehicles) {
      state.vehicleOffers = vehicles;
    },
    clearVehicles(state) {
      state.vehicles = [];
    },
    clearVehicle(state) {
      state.vehicle = null;
    },
    address(state, address) {
      state.address = address
    },
    fetchList(state, value) {
      state.fetchList = value
    },
    dealer(state, value) {
      state.dealerId = value
    },
    fetchDealerList(state, value) {
      state.fetchDealerList = value
    },
    distance(state, distance) {
      state.distance = distance
    },
    country(state, country) {
      state.country = country
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSearchString(state, search) {
      state.searchstring = search;
    },
    showOffers(state, value) {
      state.showOffers = value;
    },
    vat(state, value) {
      state.vat = value;
    },
    setLastUrlQuery(state, value) {
      state.lastUrlQuery = value;
    },
    removeFilter(state, f) {
      state.filter[f[0]] = undefined;

    },
    addFilter(state, filter) {
      PrepareFilter(state.filter, filter);
    },
    sortListBy(state, column) {
      let opt = column.split(' ');
      let col = opt[0];
      let rev = opt[1];

      state.vehicles.sort((a, b) => (a[col] > b[col]) ? 1 : -1);
      if (rev == 'desc') state.vehicles.reverse();
    },
    locationVehicleListScrollPosition(state, position) {
      state.locationVehicleListScrollPosition = position;
    },
    dealerVehicleListScrollPosition(state, position) {
      state.dealerVehicleListScrollPosition = position;
    }
};

const getters = {}

export const summery = {
  namespaced: true,
  state,
  actions,
  mutations
};
