export default function(array, filter) {

  let f = JSON.parse(JSON.stringify(filter));

  //if (typeof f.value === 'boolean') {
  if (!f.value) {
    array[f.name.toString()] = undefined;
    return true;
  }
  //}

  //if (typeof f.value === 'number') {
  if (f.value <= 0) {
    array[f.name.toString()] = undefined;
    return true;
  }
  //}

  //if (typeof f.value === 'string') {
  if (f.value == '' || f.value == '-1') {
    array[f.name.toString()] = undefined;
    return true;
  }
  //}

  array[f.name.toString()] = {
    'value': f.value,
    'operator': f.operator
  }


}
