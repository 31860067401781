<template>
<div v-view.once="inViewport" class="vcomponent large-trikebox">
  <transition name="fade" >
  <div v-bind:class="[ 'withbackground', {'eh': disabled}, {'ev': !disabled}]">
    <div v-if="!disabled" class="row">
      <div class="col-sm-8">
        <a @click="openDetailPage(vehicle.uid)">
          <div  v-bind:style="'height: 260px; background-size: cover; background-position: center center; background-image: url(&quot;https://rewaco.com/fileadmin' + imageUrl + '&quot;);'"></div>
        </a>
      </div>
      <div  class="col-sm-16" style="margin-top: 18px;">
        <div class="col-sm-16" >
          <a @click="openDetailPage(vehicle.uid)"target="_blank">
            <h3>{{ vehicle.marke }} {{ vehicle.modell }}
              <i v-if="vehicle.edition30">| <img src="@/assets/30-edition-icon.svg" /></i>
              <i v-if="vehicle.editionunique" style="text-transform: none;">| <img width="104" src="@/assets/30Unique-logo.png" /></i>
            </h3>
          </a>
          <div style="margin-top: 12px;"><b>{{$t(vehicle.fahrzeugzustand)}}</b></div>
          <div style="margin-top: 12px;">
            <div  style="float:left;" v-if="vehicle.zulassung">EZ  {{ vehicle.erstzulassung_monat }}/{{ vehicle.erstzulassung_jahr }} | </div> {{ vehicle.leistung }} kw ({{ Math.round(vehicle.leistung * 1.35962) }} PS) | {{vehicle.kilometer}} km | {{$t(vehicle.kraftstoff)}}
            | {{$t(vehicle.getriebe)}}
            <address style="margin-top:12px;">
                {{ vehicle.firma }}<br>
                {{ vehicle.strasse }}<br>
                {{ vehicle.postleitzahl }} {{ vehicle.ort }}
            </address>
          </div>

        </div>
        <div class="col-sm-8">
          <div class="row rcontainer">
            <!---->
            <div class="col-xs-24 col-sm-24 text-right ap">
              <div>
                <h3>
                <div v-if="vehicle.preis==''">&nbsp;{{$t('PREIS AUF ANFRAGE')}}</div>
                <div v-if="!vehicle.preis==''">{{ vehicle.preis | currency(vehicle.currency, 0, { decimalDigits: 0, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.' }) }}</div>
                </h3>
                <!--
                <div class="rm-price-tag-vat" style="text-align: right;">
                  {{$t(mwst[vehicle.mw_st])}}
                </div>
              -->
              </div>


            </div>
              <div class="col-sm-24 ad-nr">
                  <div>{{ $t("Anzeigennr") }}.: {{ 10000 + vehicle.uid }}</div>
            </div>

            <div v-if="vehicle.verfuegbar_ab && vehicle.verfuegbar_ab != '0000-00-00 00:00:00'" class="col-sm-24 ad-nr" sytle="margin-top: -5px;">
                <div><b>{{$t('Verfügbar ab')}}: {{ formatDate2(vehicle.verfuegbar_ab) }}</b></div>
            </div>
            <!---->
            <div class="col-xs-24 col-sm-24 text-right aq">
              <div class="row rservices" style="margin-top: 12px; ">
                <div class=" col-xs-24 col-sm-24" style="float: right;">
                  <vehicle-services :lieferservice="vehicle.lieferservice" :servicepaket="vehicle.servicepaket"></vehicle-services>
                  <div v-if="vehicle.match>0" class="bookmark" style="margin-top:8px; text-align: right;">
                    <div>{{$t('Übereinstimmung')}}</div>
                    <div v-bind:class="['bar',{'green':vehicle.match == 1},{'yellow':vehicle.match == 2}, {'yellow':vehicle.match == 3}]"></div>
                    <div v-bind:class="['bar',{'green':vehicle.match == 1}, {'yellow':vehicle.match == 2}]"></div>
                    <div v-bind:class="['bar',{'green':vehicle.match == 1}]"></div>

                  </div>
                </div>
              </div>
            </div>
            <!---->

            <!---->
          </div>
        </div>
        <div class="col-sm-24 listsubmenu">
          <div class="row">
            <div class="col-sm-24">
              <a @click="openDetailPage(vehicle.uid)" class="section"><b>{{$t("Fahrzeugdetails")}}</b>&nbsp; <i class="right arrow icon divider"></i></a>
              <a v-if="showDealerLink" @click="openDealerVehiclesPage(vehicle.haendler)" class="section"><b>{{$t("Alle Angebote des Händlers")}} <span>({{vehicle.dealervehiclecount}})</span></b>&nbsp;  <i class="right arrow icon divider"></i></a>
              <a @click="openDetailPage(vehicle.uid, true)" class="section"><b>{{$t("Kontakt")}}</b>&nbsp; <i class="right arrow icon divider"></i></a>
              <div v-if="showBookmark" class="bookmark">
                <a v-if="!vehicle.bookmarked" @click="bookmarkVehicle(vehicle.uid, true)" class="section"><b>{{$t("Merken")}}</b>&nbsp; <i class="star icon divider grey"></i></a>
                <a v-if="vehicle.bookmarked" @click="openBookmarkList()" class="section"><b>{{$t("Gemerkt")}}</b>&nbsp; <i class="star icon divider"></i></a>
              </div>

              <!--
              <a class="btn button btn-block btn-rewaco-1" @click="openDetailPage(vehicle.uid)" target="_blank">
                            {{$t("Fahrzeugdetails")}}
                        </a>
              -->
            </div>
            <!--
            <div class="col-sm-12">
              <a  class="btn button btn-block btn-rewaco-4" v-bind:href="'tel://'+vehicle.telefon1">Tel. {{vehicle.telefon1}}</a>
            </div>
          -->
          </div>
        </div>
      </div>
    </div>

  </div>
</transition>
</div>
</template>

<script>

import VehicleServices from './VehicleServices'

export default {
  name: 'VehiclePreview',
  props: ['vehicle','showDealerLink','index','showBookmark'],
  data: function() {
    return {
      body: "",
      disabled: true,
      imageUrl: null,
      //mwst: ['MwSt. nicht ausweisbar','Preis (inkl. 19% MwSt.)','Preis (inkl. MwSt.)']
    }
  },
  components: {
    VehicleServices
  },
  created: function() {
    if(this.index < 8 ){this.disabled = false;}
  },
  beforeDestroy () {
  },
  methods: {
    formatPrice: function(v,n, x, s, c) {
          var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
              num = v.toFixed(Math.max(0, ~~n));

          return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        },
    inViewport: function (e) {
        if(e.type=='enter'){
          let img = this.vehicle.imageurl ? this.vehicle.imageurl : "/user_upload/noimage.png";
          img = this.vehicle.processed ? this.vehicle.processed :  img;
          this.imageUrl = img;

          this.disabled = false;
        }
        if(e.type=='exit'){
          //this.imageUrl =  this.vehicle.imageurl
          this.disabled = true;
        }
    },
    openDetailPage: function(vehicleid, directcontact= false){

      this.$emit('selected', directcontact)

    },
    openDealerVehiclesPage: function(dealerid){

      this.$emit('dealerselected')

    },
    bookmarkVehicle: function(vehicleid, bookmark){
      this.$emit('bookmarkvehicle', bookmark)
    },
    openBookmarkList: function(){
      this.$emit('openBookmarkList')
    },
    formatDate2: function(date){
      var timeStr = date,
      intermediate = timeStr.split("T"),
      newStr = intermediate[0].split("-").join("/") + " " + intermediate[1].split(".")[0] + " GMT",
      newDate = new Date(newStr),
      newFormat = (1 + newDate.getUTCMonth()) + "." + ( 1 + newDate.getUTCDate()) + "." + newDate.getFullYear();

      return 0;//newFormat;
    },
    formatDate: function(date){

      let dt = new Date(date);
      let timestamp = dt.getTime();
      let now = Date.now();
      let format = '';

      if(timestamp>now){
        format = this.$i18n.t("Verfügbar ab") + `: ${dt.getMonth() + 1}.${dt.getFullYear()}`
      }else{
        format = this.$i18n.t("sofort verfügbar");
      }

      return format;
    }
  }
}
</script>

<style scoped>


.bookmark{
  float: right;
}

@media only screen and (max-width: 1000px) {
  .bookmark{
    float: left;
  }

}

.eh{
  visibility: hidden;
}

@-webkit-keyframes fadein{
    0%{opacity:0;visibility:visible;}
    100%{opacity:1;}
}

.ev{
  -webkit-animation:fadein 0.4s linear;
}

.bar {
    display: block;
    line-height: 1;
    position: relative;
    width:20px;
    float: right;
    margin-left:3px;
    background: #aeaeae;
    min-height: 10px;
    border-radius: .2rem;
}

.bar.green {
    background: #21ba45;
}
.bar.yellow {
    background: #fbbd08;
}

</style>
