import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'de',
  messages: {
    de: {
      'PLZ/Stadt': 'PLZ/Stadt',
      'Bitte geben Sie einen Standort ein.': 'PLZ/Stadt',
      'sofort verfügbar': 'Sofort verfügbar',
      'Zusatzleistung': "Der rewaco Lieferservice ist eine kostenpflichtige Zusatzleistung",
      'Lieferservice_Beschreibung': "Mit unserem optionalen \"Door to Door\" Lieferservice, bringen wir dir dein Trike innerhalb von 8 Tagen zu dir nach Hause! *<br><br>* Kostenpflichtige Zusatzleistung schon ab 129,- EUR inkl. MwSt.",
      'Servicepaket_Beschreibung': "Mit dem \"Service-Paket\" bieten wir dir den Transport und die Wartung zu den Inspektionen und Serviceeinsätzen mit an. *<br><br>* Kostenpflichtige Zusatzleistung auf Anfrage",
      'Fahrzeuge der Firma': 'Fahrzeuge des Händlers',
      'Übereinstimmung': 'Übereinstimmung',
      'Derzeit haben wir keine Trikes auf Lager - gerne beraten wir Sie aber bei der Konfiguration eines Neufahrzeugs.': 'Derzeit haben wir keine Trikes auf Lager - gerne beraten wir Sie aber bei der Konfiguration eines Neufahrzeugs.'
    },
    en: {
      'Meine Merkliste': 'My Bookmarks',
      'Merkliste löschen': 'Clear bookmarks',
      'Trike-Suche': 'Trike-Search',
      'Beschreibung': 'Description',
      'Land': 'Country',
      'Ort': 'Location',
      'PLZ/Stadt': 'Postcode/City',
      'Umkreis': 'Radius',
      'Suchen': 'Search',
      'teilen': 'share',
      'Schließen': 'Close',
      'Neu': 'New',
      'Anzeigennr': 'Advertisement no.',
      'Lieferservice': 'delivery service',
      'Anfrage': 'Anfrage',
      'Fahrzeugdetails': 'Vehicle details',
      'Kontaktdaten': 'Contact details',
      'Kontakt': 'Contact',
      'Deutschland': 'Germany',
      'Italien': 'Italy',
      'Frankreich': 'France',
      'Kanada': 'Canada',
      'Luxemburg': 'Luxembourg',
      'Niederlande': 'Netherlands',
      'Schweiz': 'Switzerland',
      'Vereinigte Staaten': 'United States',
      'Vereinigtes Königreich': 'United Kingdom',
      'Österreich': 'Austria',
      'Trikes gefunden': 'Trikes found',
      'Bitte geben Sie einen Standort ein.': 'Postcode/City',
      'Sortierung': 'Sort order',
      'Standort': 'Standort',
      'MwSt. nicht ausweisbar': 'VAT not included',
      'Preis (inkl. 19% MwSt.)': 'including VAT (19% Germany)',
      'Preis (inkl.': 'including VAT (',
      '% MwSt.)': '% Germany )',
      'Preis (inkl. MwSt.)': 'including VAT',
      'Preis aufsteigend': 'Price ascending',
      'Preis absteigend': 'Price descending',
      'Kilometer aufsteigend': 'kilometres ascending',
      'Kilometer': 'Kilometer',
      'Zulassung': 'Zulassung',
      'Getriebe': 'Transmission',
      'Hubraum': 'Engine capacity',
      'Kraftstoff': 'Fuel',
      'Leistung': 'Power',
      'Deine Merkliste ist leer!': 'Empty bookmark list',
      'Suche verfeinern': 'Advanced search',
      'HU min. gültig': 'Main inspection min. due',
      'Marke': 'Brand',
      'MwSt.': 'VAT',
      'Modell': 'Model',
      'Farbe': 'Color',
      'Farbe 1 (Hersteller)': 'Color 1 (Hersteller)',
      'Farbe 2 (Hersteller)': 'Color 2 (Hersteller)',
      'Bi-Colour': 'Two-Color',
      '3-Sitzer': '3 seats',
      '2-Sitzer': '2 seats',
      'Kofferraum "Tourback"': 'Trunk "Tourback"',
      'ABS': 'ABS',
      'Blinkerwarner': 'Indicator warner',
      'Rückfahrwarner': 'Backup warning system',
      'Sitzheizung': 'Seat heating',
      'XXL-Rahmen': 'XXL-Frame',
      'Touring': 'Touring',
      'Behindertengerechter Umbau': 'Handicapped version',
      'Cruise Control (Tempomat)': 'Cruise Control',
      'Anhängerkupplung': 'Trailer hitch',
      'Lenker "Style"': 'Lenker "Style"',
      'Comfort-Rahmen': 'Comfort-Frame',
      'Scheckheftgepflegt': 'Chequebook maintained',
      'Musiksystem mit Lautsprechern': 'Sound system with speakers',
      'Verfügbarkeit des Fahrzeugs': 'Vehicle availability',
      'sofort verfügbar': 'Immediately available',
      'Lieferzeit: 8 Tage': 'Delivery time: 8 days',
      'Technische Daten': 'Technical data',
      'Anzahl Fahrzeughalter': 'Number of vehicle owners',
      'Erstzulassung': 'First registration',
      'Service-Paket': 'Service pack',
      'Händler kontaktieren': 'Contact dealer',
      'Anzeigen-Nummer': 'Ad number',
      'Ausstattung': 'Features',
      'Datenschutzerklärung': 'Privacy policy',
      'Impressum': 'Imprint',
      'Anfrage zum Trike': 'Request for the Trike',
      'E-Mail': 'E-Mail',
      'PREIS AUF ANFRAGE': 'PRICE ON REQUEST',
      'Preis von/bis': 'Price from/to',
      'Alle Angebote des Händlers': 'All offers of the dealer',
      'ZUR TRIKE-SUCHE': 'TRIKE SEARCH',
      'Trike-Daten & Zustand': 'Trike data & condition',
      'Standort': 'Position',
      'Leistung ab/bis': 'Power from/to',
      'Fahrzeugzustand': 'Vehicle condition',
      'Kein passendes Fahrzeug gefunden': 'No matching vehicle found',
      'Das Fahrzeug ist nicht merh verfügbar': 'The vehicle is no longer available',
      'Das Fahrzeug mit der Anzeigen-Nummer': 'The vehicle with the ad number',
      'ist nicht mehr verfügbar': 'is no longer available',
      'Zurück zur Übersicht': 'Back to the overview',
      'Verfügbarkeit des Fahrzeugs': 'Availability of the vehicle',
      'Farbe & Ausstattung': 'Colour & Features',
      'Fahrzeuggetriebe': 'Vehicle transmission',
      'Fahrzeuge der Firma': 'Vehicles of the dealer',
      'Erweiterte Suche': 'Advanced search',
      'Gemerkt': 'Bookmarked',
      'Zurück': 'Back',
      'Merken': 'Bookmark',
      'Alle Farben': 'All colors',
      "Alle": 'All',
      'Weiß': 'White',
      'Schwarz': 'Black',
      'Gelb': 'Yellow',
      'Orange': 'Orange',
      'Grün': 'Green',
      'Blau': 'Blue',
      'Silber': 'Silver',
      'Violett': 'Purple',
      'Beige': 'Beige',
      'Braun': 'Brown',
      'Grau': 'Grey',
      'Rot': 'Red',
      'Benzin': 'Gasoline',
      'Elektro': 'Electric',
      'Automatikgetriebe': 'Automatic transmission',
      'Schaltgetriebe': 'Manual transmission',
      'Neufahrzeug': 'New Vehicle',
      'Gebrauchtfahrzeug': 'Used Vehicle',
      'Tageszulassung': 'One-day-registration',
      'Vorführfahrzeug': 'Demonstration vehicle',
      'Unfallfahrzeug': 'Accident vehicle',
      'Abonnieren': 'Subscribe',
      'Angebote': 'Offers',
      'Datenschutz': 'Privacy Policy',
      'Weitere Angebote des Händlers': 'More offers of this dealer',
      'Neue Angebote per E-Mail': 'New offers by e-mail',
      'Passende Angebote per E-Mail': 'Suitable offers by e-mail',
      'Kein passendes Angebot gefunden?': 'No suitable offer found?',
      'Hier findest Du Dein Trike zum besten Preis mit Händler Garantie!': 'Find your trike at best price and dealer warranty',
      'Lieferservice_Beschreibung': "With our optional \"door to door\" delivery service, we bring your trike to your home within 8 days! *<br><br>* additional chargeable service",
      'Servicepaket_Beschreibung': "With the \"Service Package\" we offer you transport and maintenance for inspections and service visits. *<br><br>* additional chargeable service",
      'Deine E-Mail Adresse': 'Your e-mail address',
      'Dann einfach den Suchauftrag abonnieren und informiert werden sobald ein neues Angebot verfügbar ist.': 'Then simply subscribe to the search and be informed as soon as a suitable offer is available.',
      'Dein Suchauftrag wurde erfolgreich eingetragen': 'Your search request was successfully submitted.',
      'Passend zu deinem Suchauftrag erhälst du nun die neuesten Angebote per E-Mail.': 'Matching your search request you will now receive the latest offers by e-mail.',
      'Dein Suchauftrag wurde wie gewünscht entfernt': 'Your search request has been removed as desired',
      'Du erhälst nun keine E-Mails mehr über diesen Suchauftrag. Du kannst aber jederzeit neue Suchabfragen abonnieren.': 'You will no longer receive any e-mails about your search request. However, you can subscribe to new searches at any time.',
      'Die Suchabfrage wurde nicht gefunden oder die entsprechende Aktion wurde bereits ausgeführt.': 'The search query was not found or the corresponding action has already been performed.',
      'Dein Name': 'Your name',
      'Deine E-Mail': 'Your E-Mail',
      'Deine Telefonnummer': 'Your phone number',
      'Deine Nachricht': 'Your message',
      'Ist dieses Fahrzeug noch verfügbar?': 'Is this vehicle still available?',
      'Wann könnte ich eine Probefahrt machen?': 'When could I take a test drive?',
      'Können Sie mich bitte zurückrufen?': 'Can you please call me back?',
      'Bieten Sie Finanzierungsmöglichkeiten an?': 'Do you offer financing options?',
      'Persönlicher Text': 'Personal text',
      'Ich stimme der Verarbeitung meiner Daten zu.': 'I agree to the processing of my data.',
      'Kopie an mich senden': 'Send me a copy',
      'E-Mail senden': 'Send email',
      'Deine Anfrage wurde erfolgreich eingetragen': 'Your request was successfully added',
      'Um deine Anfrage zu bestätigen erhälst du eine E-Mail': 'To confirm your request you will receive an e-mail',
      'direkt vom rewaco Partner': 'directly from rewaco partner',
      'Neu & Gebraucht': 'New & Used',
      'Wenig Kilometer': 'Few kilometres',
      'sofort verfügbar': 'immediately available',
      'vom Fachmann geprüft': 'Inspected by a specialist',
      'Deine Nachricht wurde bereits erfolgreich gesendet': 'Your message has already been successfully sent',
      'sofort': 'immediately',
      'Verfügbar ab' : 'Available',
      'Übereinstimmung': 'Match',
      'Derzeit haben wir keine Trikes auf Lager - gerne beraten wir Sie aber bei der Konfiguration eines Neufahrzeugs.': 'Currently we do not have any trikes on offer - but we would be happy to advise you on the configuration of a new vehicle.'


    },
    fr: {
      'Meine Merkliste': 'Mes favoris',
      'Merkliste löschen': 'Effacer les favoris',
      'Trike-Suche': 'Recherche trike',
      'Beschreibung': 'Description',
      'Land': 'Pays',
      'Ort': 'Ville',
      'PLZ/Stadt': 'Code postal/ville',
      'Umkreis': 'Périphérie',
      'Suchen': 'Chercher',
      'teilen': 'partager',
      'Schließen': 'Fermer',
      'Neu': 'Nouveau',
      'Anzeigennr': 'Advertisement no.',
      'Lieferservice': 'Service de livraison',
      'Anfrage': 'Requête',
      'Fahrzeugdetails': 'Détails du véhicule',
      'Kontaktdaten': 'coordonnées',
      'Kontakt': 'Contactez',
      'Deutschland': 'Allemagne',
      'Italien': 'Italie',
      'Frankreich': 'France',
      'Kanada': 'Canada',
      'Luxemburg': 'Luxembourg',
      'Niederlande': 'Pays-Bas',
      'Schweiz': 'Suisse',
      'Vereinigte Staaten': 'États-Unis',
      'Vereinigtes Königreich': 'Royaume-Uni',
      'Österreich': 'Autriche',
      'Trikes gefunden': 'trikes trouvés',
      'Bitte geben Sie einen Standort ein.': 'Code postal/ville',
      'Sortierung': 'Triage',
      'Standort': 'Localisation',
      'MwSt. nicht ausweisbar': 'TVA non incluse',
      'Preis (inkl. 19% MwSt.)': 'TVA incluse (19% Allemagne)',
      'Preis (inkl.': 'TVA incluse (',
      '% MwSt.)': '% Allemagne )',
      'Preis (inkl. MwSt.)': 'TVA incluse',
      'Preis aufsteigend': 'Price ascending',
      'Preis absteigend': 'Price descending',
      'Kilometer aufsteigend': 'kilometres ascending',
      'Kilometer': 'Kilométrage',
      'Zulassung': 'Immatriculation',
      'Getriebe': 'Boîte',
      'Hubraum': 'Cylindrée',
      'HU': 'Contrôle technique',
      'Kraftstoff': 'Carburant',
      'Leistung': 'Puissance',
      'Deine Merkliste ist leer!': 'Votre liste de surveillance est vide',
      'Suche verfeinern': 'Recherche avancée',
      'HU min. gültig': 'Inspection principale min. due',
      'Marke': 'Marque',
      'MwSt.': 'TVA',
      'Modell': 'Modèle',
      'Farbe': 'Couleurs',
      'Farbe 1 (Hersteller)': 'Couleur 1 (constructeur)',
      'Farbe 2 (Hersteller)': 'Couleur 2 (constructeur)',
      'Bi-Colour': 'Bicolore',
      '3-Sitzer': 'à 3 sièges',
      '2-Sitzer': 'à 2 sièges',
      'Kofferraum "Tourback"': 'Coffre "Tourback"',
      'ABS': 'ABS',
      'Blinkerwarner': 'Signal sonore pour clignotant',
      'Rückfahrwarner': 'Signal sonore pour marche arrière',
      'Sitzheizung': 'Sièges chauffants',
      'XXL-Rahmen': 'XXL-Rahmen',
      'Touring': 'Touring',
      'Behindertengerechter Umbau': 'Version handicapée',
      'Cruise Control (Tempomat)': 'Cruise Control',
      'Anhängerkupplung': 'attache-remorque',
      'Lenker "Style"': 'Guidon "Style"',
      'Comfort-Rahmen': 'Comfort-Frame',
      'Scheckheftgepflegt': 'Chequebook maintained',
      'Musiksystem mit Lautsprechern': 'Système sonore avec haut-parleurs',
      'Verfügbarkeit des Fahrzeugs': 'Disponibilité des véhicules',
      'sofort verfügbar': 'Immédiatement disponible',
      'Lieferzeit: 8 Tage': 'Délai de livraison : 8 jours',
      'Technische Daten': 'Coordonnés techniques',
      'Anzahl Fahrzeughalter': 'Nombre propriétaires',
      'Erstzulassung': '1ère immatriculation',
      'Service-Paket': 'Service pack',
      'Händler kontaktieren': 'Contacter concessionnaire',
      'Anzeigen-Nummer': "Numéro d'annonce",
      'Ausstattung': 'Équipement',
      'Datenschutzerklärung': 'Politique de confidentialité',
      'Impressum': 'Mentions légales',
      'Anfrage zum Trike': 'Enquête sur le Trike',
      'E-Mail': 'E-Mail',
      'PREIS AUF ANFRAGE': 'PPRIX SUR DEMANDE',
      'Preis von/bis': 'Prix de/à',
      'Alle Angebote des Händlers': 'Toutes les offres du concessionnaire',
      'ZUR TRIKE-SUCHE': 'Recherche Trike',
      'Trike-Daten & Zustand': 'Trike data & condition',
      'Standort': 'Localisation',
      'Leistung ab/bis': 'Pouvoir de/à',
      'Fahrzeugzustand': 'État du véhicule',
      'Kein passendes Fahrzeug gefunden': "Aucun véhicule correspondant n'a été trouvé",
      'Das Fahrzeug ist nicht merh verfügbar': "Le véhicule n'est plus disponible",
      'Das Fahrzeug mit der Anzeigen-Nummer': "Le véhicule avec le numéro d'annonce",
      'ist nicht mehr verfügbar': "n'est plus disponible",
      'Zurück zur Übersicht': "Retour à la vue d'ensemble",
      'Verfügbarkeit des Fahrzeugs': 'Disponibilité des véhicules',
      'Farbe & Ausstattung': 'Couleur & équipement',
      'Fahrzeuggetriebe': 'Vehicle transmission',
      'Fahrzeuge der Firma': "Véhicules de l'entreprise",
      'Erweiterte Suche': 'Recherche avancée',
      'Gemerkt': 'Favoris',
      'Zurück': 'Retour',
      'Merken': 'Favori',
      'Alle Farben': 'Toutes les couleurs',
      "Alle": 'Tous',
      'Weiß': 'Blanc',
      'Schwarz': 'Noir',
      'Gelb': 'Jaune',
      'Orange': 'Orange',
      'Grün': 'Vert',
      'Blau': 'Bleu',
      'Gold': 'Or',
      'Silber': 'Argent',
      'Violett': 'Violet',
      'Beige': 'Beige',
      'Braun': 'Brun',
      'Grau': 'Gris',
      'Rot': 'Rouge',
      'Benzin': 'Essence',
      'Elektro': 'Electric',
      'Automatikgetriebe': 'Transmission automatique',
      'Schaltgetriebe': 'Transmission manuelle',
      'Neufahrzeug': 'Véhicule neuf',
      'Gebrauchtfahrzeug': "Véhicule d'occasion",
      'Tageszulassung': 'One-day-registration',
      'Vorführfahrzeug': 'Véhicules de démonstration',
      'Unfallfahrzeug': 'Accident vehicle',
      'Abonnieren': 'S\'abonner',
      'Angebote': 'Offers',
      'Datenschutz': 'Politique de confidentialité',
      'Weitere Angebote des Händlers': 'Plusieurs offres du concessionnaire',
      'Neue Angebote per E-Mail': 'Nouvelles offres par e-mail',
      'Passende Angebote per E-Mail': 'Offres appropriées par e-mail',
      'Dann einfach den Suchauftrag abonnieren und informiert werden sobald ein neues Angebot verfügbar ist.': 'Il suffit alors de s\'inscrire à la recherche et d\'être informé dès qu\'une offre adéquate est disponible.',
      'Kein passendes Angebot gefunden?': 'Aucune offre correspondante trouvée?',
      'Hier findest Du Dein Trike zum besten Preis mit Händler Garantie!': 'Trouvez votre trike au meilleur prix et avec la garantie du concessionnaire',
      'Lieferservice_Beschreibung': "Avec notre service de livraison en option \"Porte à Porte\" , nous livrons ton Trike à domicile sous 8 jours ! *<br><br>* Service supplémentaire payant",
      'Servicepaket_Beschreibung': "With the \"Service Package\" we offer you transport and maintenance for inspections and service visits. *<br><br>* additional chargeable service",
      'Deine E-Mail Adresse': 'Votre adresse e-mail',
      'Dein Suchauftrag wurde wie gewünscht entfernt': 'Your search request has been removed as desired',
      'Du erhälst nun keine E-Mails mehr über diesen Suchauftrag. Du kannst aber jederzeit neue Suchabfragen abonnieren.': 'You will no longer receive any e-mails about your search request. However, you can subscribe to new searches at any time.',
      'Dein Name': 'Your name',
      'Deine E-Mail': 'Your E-Mail',
      'Deine Telefonnummer': 'Your phone number',
      'Deine Nachricht': 'Your message',
      'Ist dieses Fahrzeug noch verfügbar?': 'Is this vehicle still available?',
      'Wann könnte ich eine Probefahrt machen?': 'When could I take a test drive?',
      'Können Sie mich bitte zurückrufen?': 'Can you please call me back?',
      'Bieten Sie Finanzierungsmöglichkeiten an?': 'Do you offer financing options?',
      'Persönlicher Text': 'Personal text',
      'Ich stimme der Verarbeitung meiner Daten zu.': 'I agree to the processing of my data.',
      'Kopie an mich senden': 'Send me a copy',
      'E-Mail senden': 'Send email',
      'Deine Anfrage wurde erfolgreich eingetragen': 'Your request was successfully added',
      'Um deine Anfrage zu bestätigen erhälst du eine E-Mail': 'To confirm your request you will receive an e-mail',
      'direkt vom rewaco Partner': 'directly from rewaco partner',
      'Neu & Gebraucht': 'New & Used',
      'Wenig Kilometer': 'Few kilometres',
      'sofort verfügbar': 'immediately available',
      'vom Fachmann geprüft': 'Inspected by a specialist',
      'Deine Nachricht wurde bereits erfolgreich gesendet': 'Your message has already been successfully sent',
      'sofort': 'immediately',
      'Verfügbar ab' : 'Available from',
      'Übereinstimmung': 'Match',
      'Derzeit haben wir keine Trikes auf Lager - gerne beraten wir Sie aber bei der Konfiguration eines Neufahrzeugs.': 'Currently we do not have any trikes on offer - but we would be happy to advise you on the configuration of a new vehicle.'

    },
  }
});
