<template>
<div>
      <div v-if="country" class="ui large label">{{$t(getCountry(country))}}</div>
      <div v-if="address" class="ui large label">{{address.postal_code}}</div>
      <div v-if="address" class="ui large label">{{address.locality}}</div>
      <div v-for="filter in mfilter" class="ui large label" v-if="filter[1] && filter[1]!=''" style="white-space: nowrap;">
        {{$t(getFilter(filter))}}
      </div>
</div>
</template>

<script>
export default {
  name: 'NewOffers',
  props: ['filters', 'address', 'country', 'lang'],
  data: function() {
    return {
      hus: null,
      modelle: null,
      marken: null,
      fahrzeugzustaende: null,
      colors: [],
      getriebe: [],
      kraftstoff: [],
      mfilter: [],
      email: '',
      subscribed: false
    }
  },
  computed: {
    countrys() {
      return this.$store.state.summery.countrys;
    }
  },
  created() {
    this.hus = [{
      name: 'HU Neu',
      value: 99
    }, {
      name: '2 Monate',
      value: 3
    }, {
      name: '6 Monate',
      value: 6
    }, {
      name: '12 Monate',
      value: 12
    }, {
      name: '18 Monate',
      value: 18
    }];
    this.modelle = [{
      name: 'GT',
      value: 1
    }, {
      name: 'LT-2',
      value: 2
    }, {
      name: 'LT-3',
      value: 3
    }, {
      name: 'ST-2',
      value: 4
    }, {
      name: 'ST-3',
      value: 5
    }, {
      name: 'HS/FX 4',
      value: 6
    }, {
      name: 'HS/FX 5',
      value: 7
    }, {
      name: 'HS/FX 6',
      value: 8
    }, {
      name: 'HS 1',
      value: 9
    }, {
      name: 'HS 2',
      value: 10
    }, {
      name: 'HS 3',
      value: 11
    }, {
      name: 'CT800S',
      value: 12
    }, {
      name: 'CT1500S',
      value: 13
    }, {
      name: 'CT1800S',
      value: 14
    }, {
      name: 'CT1700V',
      value: 15
    }, {
      name: 'CT2300T',
      value: 16
    }]
    this.marken = [{
      name: 'rewaco',
      value: 1
    }, {
      name: 'Boom',
      value: 2
    }, {
      name: 'Can-am Spyder',
      value: 3
    }];
    this.fahrzeugzustaende = [{
      name: 'Neufahrzeug',
      value: 1
    }, {
      name: 'Gebrauchtfahrzeug',
      value: 2
    }, {
      name: 'Tageszulassung',
      value: 7
    }, {
      name: 'Vorführfahrzeug',
      value: 10
    }, {
      name: 'Unfallfahrzeug',
      value: 13
    }];
    this.colors = [{
      name: 'Gelb',
      color: "byellow"
    }, {
      name: 'Beige',
      color: "bbeige"
    }, {
      name: 'Blau',
      color: "bblue"
    }, {
      name: 'Braun',
      color: "bbrown"
    }, {
      name: 'Gold',
      color: "bgold"
    }, {
      name: 'Grau',
      color: "bgrey"
    }, {
      name: 'Grün',
      color: "bgreen"
    }, {
      name: 'Orange',
      color: "borange"
    }, {
      name: 'Rot',
      color: "bred"
    }, {
      name: 'Schwarz',
      color: "bblack"
    }, {
      name: 'Silber',
      color: "bsilver"
    }, {
      name: 'Violett',
      color: "bpurple"
    }, {
      name: 'Weiß',
      color: "white"
    }]
    this.getriebe = [{
      name: 'Automatikgetriebe',
      value: 1
    }, {
      name: 'Schaltgetriebe',
      value: 2
    }, ];
    this.kraftstoffe = [{
      name: 'Benzin',
      value: 1
    }, {
      name: 'Elektro',
      value: 2
    }, ];
    this.ausstattung = {
      'edition30': '30 Edition',
      'sitze2': '2-Sitzer',
      'sitze3': '3-Sitzer',
      'a_b_s': 'ABS',
      'anhaenger_kupplung': 'Anhängerkupplung',
      'blinker_warner': 'Blinkerwarner',
      'rueckfahrwarner': 'Rückfahrwarner',
      'comfortrahmen': 'Comfort-Rahmen',
      'sitzheizung': 'Sitzheizung',
      'umbau': 'Behindertengerechter Umbau',
      'kofferraum_tourback': 'Kofferraum "Tourback"',
      'bi_color': 'Bi-Colour',
      'lenker_style': 'Lenker "Style"',
      'x_x_l_rahmen': 'XXL-Rahmen',
      'cruisecontrol': 'Cruise Control (Tempomat)',
      'black_line': 'Black Line',
      'musiksystem': 'Musiksystem mit Lautsprecher',
      'touring': 'Touring'
    };

    this.createFilters();

  },
  watch: {
    filters(newValue, oldValue) {
      this.filters = newValue;
      this.createFilters();
    }
  },
  methods: {

    createFilters(){
      this.mfilter = Object.keys(this.filters).map((key) => {
        return [key, this.filters[key]];
      });
    },
    getFilter(filter) {

      let fm = "";

      switch (filter[0]) {
        case 'farbe_grund':
          fm = this.colors[filter[1].value - 1].name
          break;
        case 'marke':
          fm = this.marken[filter[1].value - 1].name
          break;
        case 'modell':
          fm = this.modelle[filter[1].value - 1].name
          break;
        case 'preis':
          fm = "Preis von " + filter[1].value
          break;
        case 'preis*':
          fm = "Preis bis " + filter[1].value
          break;
        case 'getriebe':
          fm = this.getriebe[filter[1].value - 1].name
          break;
        case 'kraftstoff':
          fm = this.kraftstoffe[filter[1].value - 1].name
          break;
        case 'fahrzeugzustand':
            fm = this.fahrzeugzustaende[filter[1].value - 1].name
            break;
        default:
          fm = filter[1] ? this.ausstattung[filter[0]] : undefined
          break;
      }
      return fm
    },
    getCountry(country){
      let r = "";

      for(let i in this.countrys){
        if(this.countrys[i].value == country){
          r = this.countrys[i].name;
          break;
        }
      }
      return r;
    },
    removeFilter(filter){
      this.$emit('removeFilter', filter);
    },


  }

}
</script>

<style scoped>

.ui.label {
  margin-bottom: 4px;
}

</style>
