module.exports = {
  masterpassword: 'rewaco24',
  //masterpassword: '0fab4cd6474f253c52e8480aa420d3c2',
  //apihost: 'localhost:8070',
  //protocol: 'http',

  apihost: 'api.rewaco.com',
  protocol: 'https',
  portalhome: 'https://rewaco.com',
  filedelivery: 'https://rewaco.com/fileadmin',
  available_trikes_path_de: 'sofort-verfuegbare-trikes/kaufen',
  available_trikes_path_en: 'en/available-trikes/buy',
  available_trikes_path_fr: 'fr/trikes-disponibles',
  googleapikey: 'AIzaSyA3Gms_JLrhHauLX0FftpDvyTu8Jps0wP0', // rewaco
  listChunkCount: 20,
  maxBookmarks: 10,
  privacy: {
    'de': 'https://www.rewaco.com/datenschutz/',
    'en': 'https://www.rewaco.com/en/privacy-policy/',
    'fr': 'https://www.rewaco.com/fr/politique-de-confidentialite/'
  }
}
