import {
  bookmarkService
}
from '../services';

import config from '../Config';

const state = {
  bookmarked: [],
  vehicles: []
};

const actions = {

  async addBookmark(state, vehicleId) {

      await bookmarkService.getBookmarks().then((b) => {
        this.commit('bookmarks/setBookmarks', b);
      });

      if (this.state.bookmarks.bookmarked.length < config.maxBookmarks) {
        await bookmarkService.addBookmark(vehicleId);

        await bookmarkService.getBookmarks().then((b) => {
          this.commit('bookmarks/setBookmarks', b);
        });

        return true;
      } else {
        return false;
      }
    },
    async removeBookmark(state, vehicleId) {

      await bookmarkService.removeBookmark(vehicleId);

      await bookmarkService.getBookmarks().then((b) => {
        this.commit('bookmarks/setBookmarks', b);
      });

    },
    async resetBookmarks(state) {

      await bookmarkService.resetBookmarks();
      this.commit('bookmarks/setBookmarks', []);
      this.commit('bookmarks/resetVehicles');

    },
    async getBookmarks(state) {
      await bookmarkService.getBookmarks().then((b) => {

        this.commit('bookmarks/setBookmarks', b);
      });
    },
    async fetchAllBookmarkedVehicles(state) {

      await bookmarkService.getBookmarks().then((b) => {
        state.bookmarked = b;
      });

      await bookmarkService.fetchBookmarkedVehicles(state.bookmarked)
        .then(
          data => {
            if (!data) {
              this.commit('availableVehiclesCountFailure');
            } else {
              this.commit('bookmarks/bookmarkedVehiclesSuccess', data);
            }
          })
    }
};

const mutations = {
  bookmarkedVehiclesSuccess(state, data) {
      state.vehicles = data;
    },
    setBookmarks(state, data) {
      state.bookmarked = data;
    },
    resetVehicles(state) {
      state.vehicles = [];
    }
};

const getters = {
  isBookmarked: (state, vehicleid) => {
    console.log(vehicleid)
    return state.bookmarks.bookmarked.indexOf(vehicleid) >= 0
  }

};



export const bookmarks = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
