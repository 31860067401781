<template>
  <transition name="fade">
    <div v-if="visible" v-bind:class="['overlay']">
    <div v-bind:class="['ui', format, 'message']">
      <p><b>{{$t(message)}}</b></p>
    </div>
    </div>
  </transition>

</template>

<script>

export default {
  name: 'ShortMessageOverlay',
  props: ['delay','format'],
  data: function() {
    return {
      message: "",
      visible: false,
      timeout: null
    }
  },methods: {
    showMessage: function(message){
      this.message = message;
      this.visible = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(()=>{
        this.visible = false;
      }, this.delay * 1000)

    },
    hideMessage: function(){

    }
  },
  created: function() {
    this.$parent.$on('showmessage', this.showMessage);
  }
}
</script>

<style scoped>

.ui.positive.message {
    -webkit-box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
}

.ui.positive.message {
    background-color: #fcfff5;
    color: #2c662d;
}

.ui.info.message {
    background-color: #f8ffff;
    color: #276f86;
}

.overlay{
  z-index: 8889;
  position: fixed;
  top: 90px;
  width: 100%;

}

.show{
  display: block;

}

.hide{

  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.ui.message {
  max-width: 300px;
    position: relative;
    min-height: 1em;
    margin: auto;
    background: #f8f8f9;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    color: rgba(0,0,0,.87);
    -webkit-transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease,-webkit-box-shadow .1s ease;
    -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
    text-align: center;
}
</style>
